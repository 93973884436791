/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { wrapRootElement as wrap } from './root-wrapper';

export const wrapRootElement = wrap;

export const onClientEntry = () => {
  window.onload = () => {
    document.body.style.display = '';
  };
};
