import { createGlobalStyle } from 'styled-components';
import { reset as resetAllStyles } from 'styled-reset';

export const theme = {
  color: {
    main: '#6affc9',
    mainAccent: 'rgba(106, 255, 200, 0.541)',
    header: '#01032E',
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    grey: {
      100: '#f7fafc',
      200: '#edf2f7',
      300: '#e2e8f0',
      400: '#cbd5e0',
      500: '#a0aec0',
      600: '#718096',
      700: '#4a5568',
      800: '#2d3748',
      900: '#1a202c',
    },
  },
  fonts: {
    main: 'Quicksand, sans-serif',
  },
  fontSizes: {
    small: '1em',
    medium: '1.5em',
    large: '3em',
    titleSmall: '24px',
    titleMedium: '30px',
    titleLarge: '36px',
    subTitleSmall: '18px',
    subTitleMedium: '22px',
    subTitleLarge: '24px',
    footerSmall: '12px',
    footerMedium: '13px',
    footerLarge: '14px',
  },
  spacing: {
    px: '1px',
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    8: '2rem',
    10: '2.5rem',
    12: '3rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    32: '8rem',
    40: '10rem',
    48: '12rem',
    56: '14rem',
    64: '16rem',
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  fontWeight: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  letterSpacing: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeight: {
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
  },
};

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=block');
    ${resetAllStyles}

    *, *:before, *:after {
        box-sizing: border-box;
    }

    html {
        box-sizing: border-box;
        scroll-behavior: smooth;
        font-family: ${(props) => props.theme.fonts.main};
        font-size: ${(props) => props.theme.fontSize.base};
        color: ${(props) => props.theme.color.grey[900]}
    }

    body {
        line-height: ${(props) => props.theme.lineHeight.relaxed};
        letter-spacing: ${(props) => props.theme.letterSpacing.wide};
        background-color: ${(props) => props.theme.color.white};
    }

    b {
      font-weight: ${(props) => props.theme.fontWeight.semibold};
    }
`;
